<script setup>
import { stateList } from '../../js/constants'
import {computed, reactive} from 'vue'
import { useStore } from 'vuex'

const store = useStore()
const state = reactive({
  inputValue: 'Select state',
  error: computed(() => store.getters.getError('stateError'))
})

const inputHandler = () => {
  store.commit('RESET_VALIDATION_ERROR_TO_FALSE', { validator: 'stateError' })
  if (state.inputValue) store.commit('SET_FIELD', { field: 'state' , value: state.inputValue })
  if (!state.inputValue) store.commit('SET_FIELD', {field: 'state', value: ''})
}
</script>

<template>
  <label>State:</label>
  <select
    v-model="state.inputValue"
    @change="inputHandler"
    :disabled="store.state.isLoading"
    :class="{ 'error': state.error, 'disabled': store.state.isLoading }"
  >
    <option value="Select state" disabled selected>Select state</option>
    <option
      v-for="(state, key) in stateList"
      :value="key"
      :key="key"
    >
      {{ state }}
    </option>
  </select>
</template>

<style scoped>

</style>