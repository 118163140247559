<script setup>
import { reactive } from 'vue'
import { useStore } from 'vuex'

const store = useStore()
const state = reactive({
  inputValue: ''
})

const inputHandler = () => {
  if (state.inputValue) store.commit('SET_FIELD', { field: 'comments' , value: state.inputValue })
  if (!state.inputValue) store.commit('SET_FIELD', {field: 'comments', value: ''})
}

inputHandler()
</script>

<template>
  <label>Comments:</label>
  <textarea
    v-model="state.inputValue"
    @input="inputHandler"
    :disabled="store.state.isLoading"
    :class="{ 'error': state.error, 'disabled': store.state.isLoading }"
    rows="5"
  ></textarea>
</template>

<style scoped>

</style>