<script setup>
import FormInputCompanyName from './form/FormInputCompanyName'
import FromInputFirstAndLastName from './form/FormInputFirstAndLastName'
import FormInputWebsite from './form/FormInputWebsite'
import FormInputEmail from './form/FormInputEmail'
import FormInputPhone from './form/FormInputPhone'
import FormInputPracticeArea from './form/FormInputPracticeArea'
import FormSelectState from './form/FormSelectState'
import FormSelectFirmSize from './form/FormSelectFirmSize'
import FormTextAreaComments from './form/FormTextAreaComments'
import { useStore } from 'vuex'

const store = useStore()
const validate = () => {
  store.dispatch('VALIDATE_ALL')
}
</script>

<template>
  <section class="section-container">
    <div class="form-container">
      <div class="form-header">
        <h3>Almost done!</h3>
        <p>Fill out the below and one of our account representatives will be in touch as soon as possible:</p>
        <div class="form-body">
          <div class="row">
            <div class="box">
              <form-input-company-name />
            </div>
            <div class="box">
              <from-input-first-and-last-name />
            </div>
          </div>
          <div class="row">
            <div class="box">
              <form-input-website />
            </div>
            <div class="box">
              <form-input-email />
            </div>
          </div>
          <div class="row">
            <div class="box">
              <form-input-phone />
            </div>
            <div class="box">
              <form-input-practice-area />
            </div>
          </div>
          <div class="row">
            <div class="box">
              <form-select-state />
            </div>
            <div class="box">
              <form-select-firm-size />
            </div>
          </div>
          <div class="row">
            <div class="box comments">
              <form-text-area-comments />
            </div>
          </div>
          <div class="row">
            <div class="box button-box">
              <button @click="validate">Submit</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.section-container {
  max-width: 1200px;
  margin: auto;
  padding: 10px;
  .form-container {
    padding: 20px;
    box-shadow: rgba(0, 0, 0, 0.4) 0 2px 4px, rgba(0, 0, 0, 0.3) 0 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    background-color: white;
    border-radius: 2px;
    color: #073464;
    max-width: 800px;
    .form-header {
      line-height: 35px;
      h3:first-child {
        margin-bottom: 15px;
        text-align: center;
      }
      p {
        font-weight: bolder;
        text-align: left;
        line-height: 25px;
        margin-bottom: 20px;
      }
      .row {
        display: flex;
        justify-content: space-between;
        &:last-child {
          margin-bottom: 0;
        }
        .box {
          line-height: 24px;
          width: 48.5%;
          margin-bottom: 10px;
          &.comments {
            width: 100%;
          }
          &.button-box {
            display: flex;
            justify-content: center;
            width: 100%;
            margin: 10px 0 0 0;
            button {
              max-width: 320px;
              padding: 10px;
              font-family: "Open Sans", open, sans-serif;
              font-weight: bold;
              color: #f6f6f4;
              background-color: #0f8bdb;
              text-align: center;
              font-size: 18px;
              cursor: pointer;
              text-transform: uppercase;
              &:hover {
                background-color: #12a2ff;
              }
              &:active {
                background-color: #0089de;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 530px) {
  .section-container .form-container .form-header .row {
    display: block;
    .box {
      width: 100%;
    }
  }
}
</style>