<script setup>
import { computed, reactive } from 'vue'
import { useStore } from 'vuex'

const store = useStore()
const state = reactive({
  inputValue: '',
  error: computed(() => store.getters.getError('phoneError'))
})

const inputHandler = () => {
  store.commit('RESET_VALIDATION_ERROR_TO_FALSE', { validator: 'phoneError' })
  if (state.inputValue) store.commit('SET_FIELD', { field: 'phone' , value: state.inputValue.replace(/[()\-\s]+/g, '') })
  if (!state.inputValue) store.commit('SET_FIELD', { field: 'phone', value: '' })
}
</script>

<template>
  <label>Phone Number:</label>
  <input
    v-model="state.inputValue"
    @input="inputHandler"
    :disabled="store.state.isLoading"
    :class="{ 'error': state.error, 'disabled': store.state.isLoading }"
    v-maska="'(###) ###-####'"
    type="text"
  >
</template>

<style scoped>

</style>