<script setup>
import {computed, reactive} from 'vue'
import { useStore } from 'vuex'

const store = useStore()
const state = reactive({
  inputValue: 'Select firm size',
  error: computed(() => store.getters.getError('firmSizeError'))
})

const inputHandler = () => {
  store.commit('RESET_VALIDATION_ERROR_TO_FALSE', { validator: 'firmSizeError' })
  if (state.inputValue) store.commit('SET_FIELD', { field: 'firm_size' , value: state.inputValue })
  if (!state.inputValue) store.commit('SET_FIELD', {field: 'firm_size', value: ''})
}
</script>

<template>
  <label>Firm size:</label>
  <select
    v-model="state.inputValue"
    @change="inputHandler"
    :disabled="store.state.isLoading"
    :class="{ 'error': state.error, 'disabled': store.state.isLoading }"
  >
    <option value="Select firm size" disabled selected>Select firm size</option>
    <option value="1-2">1-2</option>
    <option value="2-5">2-5</option>
    <option value="5-10">5-10</option>
    <option value="10-20">10-20</option>
    <option value="20+">20+</option>
  </select>
</template>

<style scoped>

</style>