<script setup>
import {computed, reactive} from 'vue'
import { useStore } from 'vuex'

const store = useStore()
const state = reactive({
  inputValue: '',
  error: computed(() => store.getters.getError('websiteError'))
})

const inputHandler = () => {
  store.commit('RESET_VALIDATION_ERROR_TO_FALSE', { validator: 'websiteError' })
  if (state.inputValue) store.commit('SET_FIELD', { field: 'website' , value: state.inputValue })
  if (!state.inputValue) store.commit('SET_FIELD', { field: 'website', value: '' })
}
</script>

<template>
  <label>Website:</label>
  <input
    v-model="state.inputValue"
    @input="inputHandler"
    :disabled="store.state.isLoading"
    :class="{ 'error': state.error, 'disabled': store.state.isLoading }"
    type="text"
  >
</template>

<style scoped>

</style>